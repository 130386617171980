import './App.css';
import { useEffect } from 'react';
import Router from './router/Router';
import ReactGA from 'react-ga';

function App() {
  const TRACKING_ID = 'UA-234924839-1'; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return <Router />;
}

export default App;
