import { lazy } from 'react';

const HomePage = lazy(() => import('../pages/home/HomePage'));
const AboutPage = lazy(() => import('../pages/about/About'));
const BlogPage = lazy(() => import('../pages/blog/Blog'));
const BlogDetails = lazy(() => import('../pages/blog/BlogDetails'));
const ContactPage = lazy(() => import('../pages/contact/Contact'));
const PortfolioPage = lazy(() => import('../pages/portfolio/Portfolio'));
const ServicePage = lazy(() => import('../pages/service/Service'));
const CategoryDetails = lazy(() => import('../pages/category/CategoryDetails'));

const BeachDetails = lazy(() => import('../pages/beach/BeachDetails'));
const RestaurantDetails = lazy(() =>
  import('../pages/restaurant/RestaurantDetails')
);
const Management = lazy(() => import('../pages/management/Management'));

const Analysis = lazy(() => import('../pages/management/analysis/Analysis'));

const appRoutes = [
  {
    id: 'home',
    path: '/',
    exact: true,
    component: HomePage,
  },

  {
    id: 'about',
    path: '/about',
    exact: true,
    component: AboutPage,
  },

  {
    id: 'blog',
    path: '/blog',
    exact: true,
    component: BlogPage,
  },

  {
    id: 'blog-details',
    path: '/blog/:id',
    exact: true,
    component: BlogDetails,
  },

  {
    id: 'contact',
    path: '/contact',
    exact: true,
    component: ContactPage,
  },

  {
    id: 'portfolio',
    path: '/portfolio',
    exact: true,
    component: PortfolioPage,
  },

  {
    id: 'service',
    path: '/service',
    exact: true,
    component: ServicePage,
  },

  {
    id: 'category-details',
    path: '/category/:id',
    exact: true,
    component: CategoryDetails,
  },

  {
    id: 'restaurant-details',
    path: '/restaurant/:id',
    exact: true,
    component: RestaurantDetails,
  },
  {
    id: 'beach-details',
    path: '/beach/:id',
    exact: true,
    component: BeachDetails,
  },
  {
    id: 'management',
    path: '/management',
    exact: true,
    component: Management,
  },
  {
    id: 'analysis',
    path: '/management/rental-income-calculator',
    exact: true,
    component: Analysis,
  },
];

export default appRoutes;
