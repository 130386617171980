import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import { X } from "react-feather";
import headerData from "../header/headerData";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/logo.png";

const NavDrawer = ({ isOpened, toggleDrawer }) => {
  return (
    <Drawer
      open={isOpened}
      onClose={toggleDrawer}
      direction="right"
      size={300}
      className="bg-red-600"
    >
      <div className="p-3">
        <div className="flex justify-end">
          <X className="cursor-pointer" onClick={toggleDrawer} />
        </div>

        <div className="flex flex-col gap-2 mt-4">
          {headerData.map((item) => (
            <Link
              key={item.id}
              to={item.link}
              className="w-full p-2 rounded-sm hover:bg-gray-100"
              onClick={toggleDrawer}
            >
              {item.title}
            </Link>
          ))}
        </div>

        <div className="flex justify-center mt-5">
          <Link to="/" onClick={toggleDrawer}>
            <img src={logo} alt="logo" className="h-16" />
          </Link>
        </div>
      </div>
    </Drawer>
  );
};

export default NavDrawer;
