import { Facebook, Instagram } from 'react-feather';

const SocialMediaLinks = () => {
  return (
    <div
      style={{ transform: 'translateY(-50%)' }}
      className='hidden md:block fixed top-[50%] right-[10px] z-10'
    >
      <div className='flex flex-col gap-4'>
        <a
          href='https://www.facebook.com/1848Haus'
          target='_blank'
          rel='noreferrer'
        >
          <Facebook size={16} color='black' fill='black' />
        </a>
        <a
          href='https://www.instagram.com/1848haus/'
          target='_blank'
          rel='noreferrer'
        >
          <Instagram size={16} color='black' />
        </a>
      </div>
    </div>
  );
};

export default SocialMediaLinks;
