import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "react-feather";
import useWindowSize from "../../utils/useWindowSize";
import PageView from "../pageView/PageView";
import headerData from "./headerData";
import NavDrawer from "../navDrawer/NavDrawer";
import logo from "../../assets/logo/logo.png";

const Header = () => {
  const { width } = useWindowSize();
  const { pathname } = useLocation();
  const [isNavDrawerOpened, setIsNavDrawerOpened] = useState(false);

  if (width < 786) {
    return (
      <>
        <PageView className="py-2 shadow-sm">
          <div className="flex items-center justify-between">
            <Link to="/">
              <img src={logo} alt="logo" className="h-16" />
            </Link>
            {!pathname.includes("management") && (
              <Menu
                size={25}
                className="cursor-pointer"
                onClick={() => setIsNavDrawerOpened((state) => !state)}
              />
            )}
          </div>
        </PageView>

        <NavDrawer
          isOpened={isNavDrawerOpened}
          toggleDrawer={() => setIsNavDrawerOpened((state) => !state)}
        />
      </>
    );
  }

  return (
    <div className="py-2 shadow-sm">
      <PageView>
        <div className="flex items-center justify-between">
          <Link to="/">
            <img src={logo} alt="logo" className="h-16" />
          </Link>

          {!pathname.includes("management") && (
            <div className="flex items-center space-x-5">
              {headerData.map((header) => (
                <Link key={header.id} to={header.link}>
                  {header.title}
                </Link>
              ))}
            </div>
          )}
        </div>
      </PageView>
    </div>
  );
};

export default Header;
