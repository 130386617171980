import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import appRoutes from "./appRoutes";
import SocialMediaLinks from "../components/socialMediaLinks/SocialMediaLinks";
import logo from "../assets/logo/logo.png";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {appRoutes.map((route) => {
          return (
            <Route
              key={route.id}
              exact={route.exact}
              path={route.path}
              element={
                <Suspense
                  fallback={
                    <div className="flex w-full h-[100vh] items-center justify-center">
                      <img src={logo} alt="Logo" className="w-[150px]" />
                    </div>
                  }
                >
                  <div>
                    <Header />
                    <SocialMediaLinks />
                    <route.component />
                    <Footer />
                  </div>
                </Suspense>
              }
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
