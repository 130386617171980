import { Link, useLocation } from "react-router-dom";
import PageView from "../pageView/PageView";
import headerData from "../header/headerData";
import useWindowSize from "../../utils/useWindowSize";
import logo from "../../assets/logo/logo.png";

const Footer = () => {
  const { width } = useWindowSize();
  const { pathname } = useLocation();

  if (width <= 500) {
    return (
      <div className="py-2 shadow-sm">
        <PageView>
          <img src={logo} alt="logo" className="h-16" />

          {!pathname.includes("management") && (
            <div className="grid grid-cols-2 gap-y-3 mt-3">
              {headerData.map((header) => (
                <div key={header.id}>
                  <Link to={header.link}>{header.title}</Link>
                </div>
              ))}
            </div>
          )}
        </PageView>
      </div>
    );
  }

  return (
    <div className="py-2 shadow-sm">
      <PageView>
        <div className="flex items-center justify-between">
          <Link to="/">
            <img src={logo} alt="logo" className="h-16" />
          </Link>

          {!pathname.includes("management") && (
            <div className=" md:flex md:items-center space-x-5">
              {headerData.map((header) => (
                <Link key={header.id} to={header.link}>
                  {header.title}
                </Link>
              ))}
            </div>
          )}
        </div>
      </PageView>
    </div>
  );
};

export default Footer;
